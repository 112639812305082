
import React from 'react';

import {withRouter} from "react-router-dom";
import {LocalStorageService} from "../services/localstorage.service";

import ErrorComponent from "./error";
import {queryApiGet} from "../api";
import TextField from "@mui/material/TextField";
import {NotsyAutocomlete} from "./utlisComponents";



class Demos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData: true,
            errorOccurred: false,
            demosInfo: null,

            siteId: null,
            showCreateDemoButton: true,
            showCreateDemoForm: false,
            demoLogicBranch: null,
            demoSettingsBranch: null,
            newDemoName: null,
            settingsBranchesNames: null,
            logicBranchesNames: null,
            tagsNames: null,
            accesses: LocalStorageService.getAccesses()

        }

        this.deleteDemo = this.deleteDemo.bind(this)
        this.createOrUpdateDemo = this.createOrUpdateDemo.bind(this)
        this.showCreateDemoForm = this.showCreateDemoForm.bind(this)
        this.handleDemoForm = this.handleDemoForm.bind(this)

    }

    componentDidMount() {
        this.demosSitesInfo()

        this.loadSettingsBranchesNames()
        this.loadLogicBranchesNames()
        this.loadTagsNames()
    }

    loadSettingsBranchesNames() {
        queryApiGet('/api/settingsBranchNames').then(
            (result) => {
                console.log(result)

                this.setState({
                    settingsBranchesNames: result
                })
            },
        ).catch((e) => this.setErrorState(e))
    }

    loadLogicBranchesNames() {
        queryApiGet('/api/logicBranchNames').then(
            (result) => {
                this.setState({
                    logicBranchesNames: result
                })
            }
        ).catch((e) => this.setErrorState(e))
    }

    loadTagsNames() {
        queryApiGet('/api/tagsNames').then(
            (result) => {
            console.log(result)

            this.setState({
                tagsNames: result
            })
        }).catch((e) => this.setErrorState(e))
    }

    demosSitesInfo() {
        this.setState({
            loadingData: true,
            showCreateDemoButton: false
        })

        let url = `/api/demosStatuses?siteId=${this.props.match.params.siteId}`

        queryApiGet(url).then(
            (result) => {
                console.log(result)

                this.setState({
                    loadingData: false,
                    demosInfo: result,
                    siteId: this.props.match.params.siteId,
                    showCreateDemoButton: true
                })
            }
        ).catch(e => this.setErrorState(e))
    }

    deleteDemo(demoId, siteId) {

        this.setState({
            loadingData: true,
            showCreateDemoButton: false
        })

        if (demoId !== undefined) {

            let url = `/api/deleteDemo?demoId=${demoId}&siteId=${siteId}`
            queryApiGet(
                url,
            ).then(
                (result) => {
                    if (result.ok) {
                        this.demosSitesInfo()
                        this.setState({
                            loadingData: false,
                            showCreateDemoButton: true
                        })
                    }
                }
            ).catch(e => this.setErrorState(e))
        }
    }


    createOrUpdateDemo(siteId, demoId, logicCommitish, settingsBranch) {

        this.setState({
            loadingData: true,
            showCreateDemoButton: false
        })

        let url = `/api/createOrUpdateDemo?siteId=${siteId}&demoId=${demoId}&logicCommitish=${logicCommitish}&settingsBranch=${settingsBranch}`
        queryApiGet(
            url,
        ).then(
            (result) => {
                if (result.ok) {
                    this.demosSitesInfo()
                    this.setState({
                        loadingData: false,
                        showCreateDemoButton: true
                    })
                }
            },
        ).catch(e => this.setErrorState(e))

    }

    setErrorState(errorMessage) {
        const res = [...this.state.errorMessages || [], errorMessage]

        this.setState({errorMessages: res})
    }

    showCreateDemoForm() {
        this.setState({
            showCreateDemoButton: false,
            showCreateDemoForm: true
        })
    }

    handleDemoForm(e) {

        e.preventDefault()

        let newDemoName = document.getElementById('new_demo_name').value
        let demoSettingsBranch = 'origin/' + document.getElementById('settings_branch').value
        let demoLogicBranch = 'origin/' + document.getElementById('logic_branch').value

        this.setState({
            loadingData: true,
            showCreateDemoButton: false,
            showCreateDemoForm: false,
        })

        this.createOrUpdateDemo(this.state.siteId, newDemoName, demoLogicBranch, demoSettingsBranch)
    }


    render () {
        if (this.state.errorMessages?.length) {
            return <ErrorComponent errorMessages={this.state.errorMessages}></ErrorComponent>
        }

        if (this.state.loadingData) {
            return (
                <div style={{textAlign: "center"}}>
                    Loading. Please wait...
                </div>
            )
        } else if(this.state.demosInfo[0]) {

            const demosInfoRows = this.state.demosInfo.map((demoInfo, index) => <BuildDemoInfo createOrUpdateDemo={this.createOrUpdateDemo} siteId={this.state.siteId} deleteDemo={this.deleteDemo} build={demoInfo} key={index} />)

            return (
                <React.Fragment>
                    <p>Demos {this.state.siteId}</p>
                    <table border={1} width={'85%'} style={{fontSize: 19}}>
                        <thead>
                        <tr>
                            <td rowSpan={2}>ID</td>
                            <td colSpan={4}>Build info</td>
                            <td rowSpan={2}>Deployed At</td>
                            <td rowSpan={2}>Controls</td>
                        </tr>
                        <tr>
                            <td>Logic</td>
                            <td>Logic Hash</td>
                            <td>Settings</td>
                            <td>Settings Hash</td>
                        </tr>
                        </thead>
                        <tbody>
                        {demosInfoRows}
                        </tbody>
                    </table>

                    {this.state.showCreateDemoButton ? <button onClick={() => this.showCreateDemoForm()} style={{color: '#000000', width: '250px', marginTop: 50, height: '60px', fontSize: 25, cursor: 'pointer'}}>Create new demo</button> : null}
                    {this.state.showCreateDemoForm ?
                        <div style={{marginTop: 150}}>
                            <p>Create Demo</p>
                            <form style={{minWidth: '60vw'}} onSubmit={this.handleDemoForm}>
                                <SelectLogicBranch siteId={this.state.siteId} tagsNames={this.state.tagsNames} branchesNames={this.state.logicBranchesNames}/>
                                <SelectSettingsBranch siteId={this.state.siteId} branchesNames={this.state.settingsBranchesNames}/>
                                <InputDemoName  />
                                <button onClick={() => this.handleDemoForm} style={{width: '250px', height: '60px', fontSize: 25, marginTop: '20px', cursor: 'pointer'}}>Create demo</button>

                            </form>
                        </div> : null
                    }
                </React.Fragment>
            )
        } else if(this.state.showCreateDemoButton) {
            return <button onClick={() => this.showCreateDemoForm()} style={{color: '#000000', width: '250px', marginTop: 50, height: '60px', fontSize: 25, cursor: 'pointer'}}>Create new demo</button>
        } else if(this.state.showCreateDemoForm) {
            return (
                <React.Fragment>
                    <p>Create Demo</p>
                    <form style={{minWidth: '60vw'}} onSubmit={this.handleDemoForm}>
                        {this.state.logicBranchesNames ?
                            <SelectLogicBranch siteId={this.state.siteId} tagsNames={this.state.tagsNames} branchesNames={this.state.logicBranchesNames}/>
                        : null
                        }
                        {this.state.settingsBranchesNames ?
                            <SelectSettingsBranch siteId={this.state.siteId} branchesNames={this.state.settingsBranchesNames}/>
                        : null
                        }
                        <InputDemoName  />
                        <button onClick={() => this.handleDemoForm} style={{width: '250px', height: '60px', fontSize: 25, marginTop: '20px', cursor: 'pointer'}}>Create demo</button>
                    </form>
                </React.Fragment>
            )
        } else {
            return (
                <div style={{textAlign: "center"}}>
                    You don't have demos for this site
                </div>
            )
        }
    }
}

class BuildDemoInfo extends React.Component {

    constructor() {
        super();

        this.state = {
            accesses: LocalStorageService.getAccesses()
        }
    }
    render () {
        if(this.props.build) {
            return (
                <tr>
                    <td>
                        {this.props.build.demoId}
                    </td>
                    <td>
                        {this.props.build.logic}
                    </td>
                    <td>
                        {this.props.build.logicHash}
                    </td>
                    <td>
                        {this.props.build.settings}
                    </td>
                    <td>
                        {this.props.build.settingsHash}
                    </td>
                    <td>
                        {this.props.build.deployedAt}
                    </td>
                    <td>

                        <button onClick={() => this.props.deleteDemo(this.props.build.demoId, this.props.siteId)} style={{color: '#ffff99', fontSize: 18, border: 'none', background: 'none', textDecoration: 'underline', cursor: 'pointer'}}>Delete</button>
                        <button onClick={() => this.props.createOrUpdateDemo(this.props.siteId, this.props.build.demoId, this.props.build.logic, this.props.build.settings)} style={{color: '#ffff99', fontSize: 18, border: 'none', cursor: 'pointer', background: 'none', textDecoration: 'underline'}}>Update</button>

                    </td>
                </tr>
            )
        }
    }
}


class SelectLogicBranch extends React.Component {
    render() {
        return (
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <div>Choose logic branch for site {this.props.siteId}</div>
                <NotsyAutocomlete
                    disablePortal
                    id="logic_branch"
                    options={this.props.branchesNames}
                    sx={{ width: '70%', color: 'white' }}
                    renderInput={(params) => <TextField {...params} label="Logic branch" />}
                />
                <br />
            </div>
        )
    }
}

class SelectSettingsBranch extends React.Component {
    render() {
        return (
            <div style={{marginTop: 35, display: 'flex', flexDirection: 'column', alignItems: "center"}}>
                <div>Choose settings branch for site {this.props.siteId}</div>
                <NotsyAutocomlete
                    disablePortal
                    id="settings_branch"
                    options={this.props.branchesNames}
                    sx={{ width: '70%', color: 'white' }}
                    renderInput={(params) => <TextField {...params} label="Settings branch" />}
                />
            </div>
        )
    }
}

class InputDemoName extends React.Component {
    render() {
        return (
            <div style={{marginTop: 35}}>
                Choose demo ID for site {this.props.siteId}<br />
                <input type={'text'} pattern={'[a-zA-Z0-9_-]{3,35}'} id="new_demo_name" name="new_demo_name" style={{width: '70%', fontSize: 20}} title="Demo name should only contain english letters, numbers and next symbols: '_' '-'" required />
                <br />
            </div>
        )
    }
}


export default withRouter(Demos)
